.contact-us {
    .page-banner {
        background: url('../../../assets/los-angeles-downtown-banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 150px 0px;
        position: relative;

        .overlay-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
                font-size: 50px;
                font-weight: 800;
                color: #FFF;
                margin-bottom: 0px;
                text-align: center;
            }

            h2 {
                font-size: 20px;
                font-weight: 500;
                font-style: italic;
                margin-top: 15px;
                margin-bottom: 0px;
                line-height: 30px;
                color: #FFF;
                text-align: center;
            }
        }
    }

    .details {
        padding: 60px 0px;

        .contact-form {
            background-color: #F5F5F5;
            border-radius: 5px;
            width: 100%;
            padding: 20px 30px;

            .form-heading {
                margin-bottom: 15px;

                h2 {
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 5px;
                }
            }

            .submit-btn {
                background-color: #096CC9;
                font-size: 14px;
                font-weight: 600;
                border: 2px solid #096CC9;
                margin-top: 15px;
                transition: background-color 0.3s ease;
            }

            .submit-btn:hover {
                background-color: #FFF;
                color: #096CC9;
            }
        }

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }
}