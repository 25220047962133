.footer {
    background-color: rgba($color: #096CC9, $alpha: 0.1);

    .footer-main {
        padding: 30px 0px;

        .agent-name {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 0px;
            color: #000;
        }

        .agent-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;

            .details-box {
                h5 {
                    font-size: 18px;
                    font-weight: 700;
                    color: #000;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    margin-bottom: 0px;
                    margin-top: 5px;
                }

                a {
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    margin-bottom: 0px;
                    display: block;
                    text-decoration: none;
                    margin-top: 5px;
                }
            }
        }

        .footer-small-info {
            margin-top: 30px;
            font-size: 14px;
            font-weight: 400;
            color: #000;
            line-height: 18px;
        }
    }

    .copyright-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0px;
        border-top: 1px solid rgba($color: #000000, $alpha: 0.3);

        h5 {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 0px;
            color: #000;

            a {
                color: #323232;
                text-decoration: underline;
            }
        }

        .links {
            display: flex;
            align-items: center;

            .social-media-box {
                display: block;
                height: 35px;
                width: 35px;
                border-radius: 50%;
                border: 2px solid #323232;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
            }

            .social-media-box:last-child {
                margin-right: 0px;
            }

            .social-media-box:hover {
                background-color: #323232;

                svg {
                    fill: #FFF;
                }
            }
        }
    }
}